import React from 'react';
import {WidgetProps} from 'yoshi-flow-editor-runtime';
import {IControllerProps} from '../../../types/app.types';
import {ControllerContext} from './ControllerContext';
import ThankYouPageApp from './ThankYouPageApp/ThankYouPageApp';

export default function ThankYouPageAppRoot(controllerProps: WidgetProps<IControllerProps>) {
  return (
    <ControllerContext value={controllerProps}>
      <ThankYouPageApp host={controllerProps.host} ravenUserContextOverrides={{}} />
    </ControllerContext>
  );
}
