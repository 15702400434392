import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import s from './ContinueShopping.scss';
import settingsParams from '../../../settingsParams';
import {useSettings} from 'yoshi-flow-editor-runtime/tpa-settings/react';

export enum ContinueShoppingDataHook {
  root = 'ContinueShoppingDataHook.root',
  link = 'ContinueShoppingDataHook.link',
}

const handleClick = (e: React.MouseEvent, callback: Function) => {
  e.preventDefault();
  callback();
};

export function ContinueShopping() {
  const {get} = useSettings();
  const {continueLinkHref, onContinueLinkClick} = useControllerProps().navigationStore;

  return (
    <div data-hook={ContinueShoppingDataHook.root} className={s.root}>
      <a
        href={continueLinkHref}
        className={s.link}
        onClick={(e) => handleClick(e, onContinueLinkClick)}
        data-hook={ContinueShoppingDataHook.link}>
        {get(settingsParams.THANK_YOU_PAGE_CONTINUE_SHOPPING_LINK_CAPTION)}
      </a>
    </div>
  );
}
