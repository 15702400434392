import React from 'react';
import {useStyles} from 'yoshi-flow-editor-runtime/tpa-settings/react';
import {LocaleKeysProvider} from '../../LocaleKeysProvider';
import {Header} from '../Components/Header/Header';
import {Layout} from '../Components/Layout/Layout';
import stylesParams from '../../stylesParams';
import {ContinueBrowsing} from '../Components/ContinueBrowsing/ContinueBrowsing';

export enum ThankYouPageContentDataHook {
  content = 'ThankYouPageAppDataHook.content',
}

export function PageContent() {
  const {get} = useStyles();
  const shouldRenderContinueBrowsingLink = get(stylesParams.THANK_YOU_PAGE_CONTINUE_BROWSING_LINK_SELECTION);

  return (
    <LocaleKeysProvider>
      <div data-hook={ThankYouPageContentDataHook.content}>
        <Header />
        <Layout />
        {shouldRenderContinueBrowsingLink && <ContinueBrowsing />}
      </div>
    </LocaleKeysProvider>
  );
}
