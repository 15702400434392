import React from 'react';
import s from './DigitalFiles.scss';
import {useTranslation} from 'yoshi-flow-editor-runtime';
import {useControllerProps} from '../../ControllerContext';
import {IOrderItem} from '@wix/wixstores-graphql-schema';
import {DownloadIcon} from '../Icons/Download';

export enum DigitalFilesDataHook {
  root = 'DigitalFilesDataHook.root',
  title = 'DigitalFilesDataHook.title',
  item = 'DigitalFilesDataHook.item',
  itemName = 'DigitalFilesDataHook.itemName',
  itemLink = 'DigitalFilesDataHook.itemLink',
  itemLinkText = 'DigitalFilesDataHook.itemLinkText',
}

type DigitalFileProps = {
  item: IOrderItem;
};

const DigitalFile = ({item}: DigitalFileProps) => {
  const {t} = useTranslation();
  const {reportDigitalItemDownloadClickBi} = useControllerProps().thankYouPageStore;

  const handleDownloadClick = () => {
    reportDigitalItemDownloadClickBi();
  };

  return (
    <li className={s.item} data-hook={DigitalFilesDataHook.item}>
      <div className={s.itemName} data-hook={DigitalFilesDataHook.itemName}>
        {item.name}
      </div>
      <div>
        <a
          className={s.itemLink}
          href={item.digitalFileLink}
          onClick={handleDownloadClick}
          target="_blank"
          download
          rel="noreferrer"
          data-hook={DigitalFilesDataHook.itemLink}>
          <DownloadIcon className={s.itemLinkIcon} />
          <span className={s.itemLinkText} data-hook={DigitalFilesDataHook.itemLinkText}>
            {t('thankYou.DOWNLOAD_LINK_LABEL')}
          </span>
        </a>
      </div>
    </li>
  );
};

export const DigitalFiles = () => {
  const {t} = useTranslation();
  const {digitalItems} = useControllerProps().thankYouPageStore;

  return (
    <div data-hook={DigitalFilesDataHook.root}>
      <div className={s.title} data-hook={DigitalFilesDataHook.title}>
        {t('thankYou.DOWNLOAD_LINKS_LABEL')}
      </div>
      <ul>
        {digitalItems.map((item) => (
          <DigitalFile item={item} key={item.productId} />
        ))}
      </ul>
    </div>
  );
};
