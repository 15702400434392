import React from 'react';
import {Divider} from 'wix-ui-tpa/Divider';
import {ShippingSection} from '../ShippingSection/ShippingSection';
import {TotalsSection} from '../TotalsSection/TotalsSection';
import s from './Layout.scss';
import {ProductLineItems} from '../ProductLineItemsSection/ProductLineItems';
import {useControllerProps} from '../../ControllerContext';
import {shouldShowBilling, shouldShowShipping} from './utils';

export enum LayoutDataHook {
  root = 'LayoutDataHook.root',
  itemsSection = 'LayoutDataHook.itemsSection',
  totalsSection = 'LayoutDataHook.totalsSection',
  shippingSection = 'LayoutDataHook.shippingSection',
  productLineItem = 'LayoutDataHook.productLineItem',
}

export const Layout = () => {
  const {
    billingAddress,
    address: shippingAddress,
    totalPrice,
    pickupAddress,
    isPickupSelected,
  } = useControllerProps().thankYouPageStore;

  const showBilling = shouldShowBilling({billingAddress, totalPrice});
  const showShipping = shouldShowShipping({isPickupSelected, pickupAddress, shippingAddress});

  return (
    <div className={s.root} data-hook={LayoutDataHook.root}>
      <div className={s.box}>
        <Divider className={s.divider} />
        <section className={s.lineItemsSection} data-hook={LayoutDataHook.itemsSection}>
          <ProductLineItems />
        </section>
        <Divider className={s.divider} />
        <section className={s.totalsSection} data-hook={LayoutDataHook.totalsSection}>
          <TotalsSection />
        </section>
      </div>
      {(showShipping || showBilling) && (
        <div className={`${s.box} ${s.shippingBox}`}>
          <section className={s.shippingSection} data-hook={LayoutDataHook.shippingSection}>
            <ShippingSection
              isPickupSelected={isPickupSelected}
              shouldShowShipping={showShipping}
              shouldShowBilling={showBilling}
            />
          </section>
        </div>
      )}
    </div>
  );
};
