import React, {createContext, useContext, FC} from 'react';
import {useTranslation} from 'yoshi-flow-editor-runtime';
import {LocaleKeys} from '../../locale-keys/LocaleKeys';

const LocaleKeysContext = createContext<LocaleKeys>(undefined);

export const LocaleKeysProvider: FC = ({children}) => {
  const {t} = useTranslation();
  const localeKeys = new LocaleKeys(t);

  return <LocaleKeysContext.Provider value={localeKeys}>{children}</LocaleKeysContext.Provider>;
};

export const useLocaleKeys = () => useContext(LocaleKeysContext);
